/*
    Calendar Controller
    An implementation of calendar interface.
*/
frappe.provide("client_portal.appt");

client_portal.appt.CalendarController = class CalendarController{

    constructor(args){
        $.extend(this, args);
        this.weekdays_no_to_name = {
            "1": "Mon",
            "2": "Tue",
            "3": "Wed",
            "4": "Thu",
            "5": "Fri",
            "6": "Sat",
            "7": "Sun"
        };
        this.now_date = frappe.datetime.str_to_obj(frappe.datetime.nowdate());
        console.log(this.now_date);
        this.make();
    }

    has_available_slots(){
        return this.available_slots && this.available_slots.length?true:false;
    }

    make(){
        this.set_calendar_options();
        this.$input = this.schedule_controller.$date_wrapper.find("input");
        let $inputContainer = $('.select-date-picker-wrapper');
        this.datepicker = this.$input.datepicker({
          format: 'yyyy/mm/dd',
          templates: {
              leftArrow: '<span class="arrow-icon arrow-icon-left"><svg id="arrow_back" data-name="arrow back" xmlns="http://www.w3.org/2000/svg" width="16.105" height="9.71" viewBox="0 0 16.105 9.71"><path id="Контур_92476" data-name="Контур 92476" d="M4.855,0,0,4.855,4.855,9.709,6.078,8.473,3.326,5.721H16.105V3.988H3.326L6.078,1.236Z" transform="translate(0 0)" fill="#161616"/></svg></span>',
              rightArrow: '<span class="arrow-icon arrow-icon-right"><svg id="arrow_next" data-name="arrow next" xmlns="http://www.w3.org/2000/svg" width="16.105" height="9.71" viewBox="0 0 16.105 9.71"><path id="Контур_92476" data-name="Контур 92476" d="M4.855,0,0,4.855,4.855,9.709,6.078,8.473,3.326,5.721H16.105V3.988H3.326L6.078,1.236Z" transform="translate(0 0)" fill="#161616"/></svg></span>'
          },
          startDate: new Date(),
          container: $inputContainer,
          multidate: false,
          autoclose: false,
        });

        this.handle_date_picker_events();

        setTimeout(()=>{
            this.datepicker.datepicker("setDate", frappe.datetime.nowdate());
        }, 1500);
    }

    set_calendar_options(){
        $.fn.datepicker.dates['en'] = {
            days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            daysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            today: "Today",
            clear: "Clear",
            format: "mm/dd/yyyy",
            titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
            weekStart: 0
        };
    }

    handle_date_picker_events(){
        this.datepicker.datepicker("show");
        this.datepicker.on("changeDate", (selected_date)=>{
            this.set_available_slots(selected_date).then((slots)=>{
                this.schedule_controller.calendar_date_callback(selected_date, slots);
            });
            return false;
        });
        
        this.datepicker.on("hide", (e)=>{
            return false;
        });
        this.$input.data('datepicker').hide = function () {};
    }

    set_available_slots(selected_calendar_data){
        return new Promise((resolve, reject)=>{
            try{
                let selected_date = selected_calendar_data.date;
                let string_formatted_selected_date = selected_calendar_data.format();
                this.data = this.schedule_controller.set_schedule_data();
                this.possible_closed_dates = this.data.possible_closed_dates;
                let appointment_time =  this.schedule_controller.get_appointment_time();
                let options = [];
                if(this.possible_closed_dates[string_formatted_selected_date]){
                    options = this.get_existing_time_slots(selected_date);
                }else{
                    options = this.generate_time_slots(appointment_time, selected_date);
                }
                let formatted_time;
                options.forEach((val)=>{
                    formatted_time = this.format_time(val.starts_on);
                    val.value = __("{0},{1},{2}", [frappe.datetime.get_datetime_as_string(val.starts_on), frappe.datetime.get_datetime_as_string(val.ends_on), val.google_calendar]);
                    val.label = `${formatted_time.split(" ")[0]} <span>${formatted_time.split(" ")[1]}</span>`;
                });
                //this.dialog.set_df_property("timeslot", "read_only", 0);
                //this.dialog.set_df_property("timeslot", "options", options);
                this.set_time_slots(selected_date, options);
                resolve(this.available_slots);
            }catch(e){
                reject(e);
            }
        });
    }

    set_time_slots(selected_date, slots){
        let $slots_wrapper = this.schedule_controller.$time_selection_wrapper.find(".time-slots-wrapper").empty();
        this.set_selected_date(selected_date);
        this.selected_slot = {};
        this.available_slots = slots;
        this.available_slots_dict = {};
        slots.forEach((slot)=>{
            this.available_slots_dict[slot.value] = slot;
            $(`<div class="bac-time-wrapper">
                <label class="bac-time-hour-time-item w-100 d-flex justify-content-between align-items-center btn btn-outline-warning">
                    <input type="radio" name="bac-time-hour" value="${slot.value}"
                        class="js-next-field-radio js-date-coverage">
                    <span class="label-placeholder helvetica-font mr-sm-3">${slot.label}</span>

                    <span class="circle-icon">
                        <svg id="Star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13.256" height="13.183" viewBox="0 0 13.256 13.183">
                            <defs>
                            <clipPath id="clip-path">
                                <rect id="Прямоугольник_5179" data-name="Прямоугольник 5179" width="13.256" height="13.183" transform="translate(0 0)" fill="none"/>
                            </clipPath>
                            </defs>
                            <g id="Сгруппировать_36839" data-name="Сгруппировать 36839" transform="translate(0 0)" clip-path="url(#clip-path)">
                            <path id="Контур_92589" data-name="Контур 92589" d="M6.154.341a.5.5,0,0,1,.948,0l1.29,3.837a.5.5,0,0,0,.461.341l3.917.105a.5.5,0,0,1,.3.889L9.919,8.052a.5.5,0,0,0-.167.525l1.121,3.97a.5.5,0,0,1-.774.541l-3.177-2.3a.5.5,0,0,0-.586,0l-3.177,2.3a.5.5,0,0,1-.774-.541l1.121-3.97a.5.5,0,0,0-.167-.525L.186,5.513a.5.5,0,0,1,.3-.889L4.4,4.519a.5.5,0,0,0,.461-.341Z" fill="#fff"/>
                            </g>
                        </svg>
                    </span> 
                </label>
            </div>`).appendTo($slots_wrapper);
        });

        $slots_wrapper.find("input").on("change", (e)=>{
            let value = $(e.currentTarget).val();
            this.set_selected_slot(value);
            this.set_header_date();
            this.schedule_controller.enable_confirm();
        });
    }

    set_selected_slot(value){
        this.selected_slot = this.available_slots_dict[value];
    }

    set_header_date(){
        let formatted_data = this.get_start_end_and_date_in_formatted(this.selected_slot);
        this.schedule_controller.$user_info_wrapper.find(".user-header-date")
            .html(`<span><b>${formatted_data.starts_on} - ${formatted_data.ends_on} ${formatted_data.date}</b></span>`);
        
        this.schedule_controller.$user_info_wrapper.find(".user-header-date-icon")
            .addClass("d-flex").removeClass("d-none");
    }

    set_selected_date(selected_date){
        let selected_formatted_date = this.format_date(moment(selected_date));
        this.schedule_controller.$time_selection_wrapper.find(".current-selected-date-slot-title")
            .html(`<span>${selected_formatted_date}</span>`);
    }

    generate_time_slots(appointment_time, selected_date){
        let short_weekday = this.get_short_weekday(selected_date.getDay());
        let shift_details = this.get_shift_start_end_time(short_weekday);
        let slots = [];
        if(shift_details !== null){
            let shift_start_time = moment(selected_date).add(shift_details.shift_starts_on_in_seconds, "seconds");
            if(moment(selected_date).isSame(moment(this.now_date))){
                shift_start_time = this.get_now_shift_start_time();
                console.log(frappe.datetime.get_datetime_as_string(shift_start_time));
            }
            let shift_end_time = moment(selected_date).add(shift_details.shift_ends_on_in_seconds, "seconds");
            let temp;
            while(shift_start_time.isBefore(shift_end_time)){
                temp = shift_start_time.clone().add(appointment_time, "minute");
                slots.push({
                    "_starts_on": frappe.datetime.get_datetime_as_string(shift_start_time),
                    "_ends_on": frappe.datetime.get_datetime_as_string(temp),
                    "starts_on": shift_start_time,
                    "ends_on": temp,
                    "formatted_starts_on": this.format_time(shift_start_time),
                    "formatted_ends_on": this.format_time(temp),
                    "google_calendar": $.isArray(shift_details.google_calendars)?shift_details.google_calendars[0]:shift_details.google_calendars.split("\n"),
                })
                shift_start_time = temp;
            }
        }
        return slots;
    }

    get_now_shift_start_time(){
        let now_date = moment(frappe.datetime.str_to_obj(frappe.datetime.now_datetime()));
        let minutes = now_date.minutes();
        let mins_to_add = 0;
        now_date.set("second", 0);
        if(minutes <= 30){
            mins_to_add = 60 - now_date.get("minute");
        }else if(minutes <= 60){
            mins_to_add = 60-now_date.get("minute") + 30;
        }else{
            mins_to_add = 30;
        }
        return now_date.add(mins_to_add, "minutes");
    }

    get_short_weekday(day_no){
        return this.weekdays_no_to_name[day_no];
    }

    get_shift_start_end_time(week_day){
        let data = null;
        if(this.data.universal_shift_timing && this.data.universal_shift_timing[week_day]){
            data = this.data.universal_shift_timing[week_day];
        }
        return data;
    }

    format_time(_datetime){
        return _datetime.format("hh:mm A");
    }

    format_date(_datetime){
        return _datetime.format("dddd, MMMM DD, YYYY");
    }

    get_start_end_and_date_in_formatted(selected_slot){
        return {
            starts_on: this.format_time(selected_slot.starts_on),
            ends_on: this.format_time(selected_slot.ends_on),
            date: this.format_date(selected_slot.starts_on),
        };
    }
}