frappe.provide("client_portal.appt");

import "./schedule_controller";

client_portal.appt.AppointmentController = class AppointmentController{

    constructor(args){
        $.extend(this, args);
        this.is_mobile_device = bond_assets.device.is_mobile_device();
        this.hide_call_button();
        this.make();
    }

    hide_call_button(){
        $(".btn-notice-mobile").addClass("d-none").removeClass("d-lg-flex");
        $(".nav-mobile-btn").removeClass("d-lg-flex");
    }

    set_defaults(){
        return new Promise((resolve, reject)=>{
            this.$parent = this.get_wrapper();
            this.$parent.find(".book-a-call").on("submit", (e)=>{
                e.preventDefault();
                return false;
            });
            this.$confirm_message = this.$parent.find(".confirmation-wrapper");
            this.setup_mobile_classes();
            this.$confirm_message.find(".confirm-go-home").on("click", (e)=>{
                e.preventDefault();
                window.location.href= "https://www.trybond.com";
                return false;
            });
            this.$parent
                .css("height", "1px");
            
            this.$wrapper = this.$parent.find(".js-bac-starter");
            this.set_title();

            this.search_params = new URL(document.location).searchParams;
            resolve();
        });
    }

    setup_mobile_classes(){
        if(this.is_mobile_device){
        }
    }

    async  make(){
        await this.set_defaults();

        this._data = await this.get_services();
        this.set_services(this._data);
        this.appointment_controller = new client_portal.appt.AppointmentCancelationController({
            appointment_controller: this
        });
        this.$parent.find(".book-a-call").removeClass("d-none");
    }

    show(){
        this.show_services_wrapper();
        this.hide_schedule_wrapper();
    }

    get_default_user_values(){
        let values = {};
        if(this._data && this._data.default_values){
            values = this._data.default_values;
        }
        return values;
    }

    get_services(){
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "client_portal.www.appt.get_services",
                args:{
                    data: this.get_default_data(),
                },
                freeze: true,
                callback: (res)=>{
                    resolve(res);
                }
            });
        });
    }

    set_services(data){
        let previous_selected_services = [];
        let services = data.services;
        if(data.default_values && data.default_values.services){
            previous_selected_services = data.default_values.services;
        }
        this.selected_services = [];
        this.$services_wrapper = this.$parent.find(".services-main-wrapper");
        let has_service = false;
        let found_service = false;
        services.forEach(detail => {
            let service_group = detail.service_group;
            let items = detail.items;
            let cls = "flex-column";
            if(service_group == "Commercial"){
                cls = "flex-wrap";
            }
            let $wrapper = $(`<div class="bac-starter__form-col">
                <div class="bac-starter__form-label">
                    <span>${service_group.toUpperCase()}</span>
                </div>
                <div class="btn-group-toggle btn-group-lg d-flex ${cls}"></div>
            </div>`).appendTo(this.is_mobile_device?this.$services_wrapper:this.$services_wrapper.find(".services-wrapper"));
            let $checkbox;
            items.forEach((item)=>{
                $checkbox = $(`<label class="w-100 d-flex flex-column btn tab-link tab-link--short">
                        <input type="checkbox" name="calendar-products-financial" value="${item.value}"
                            class="js-bcp-field-radio">
                        <span class="circle-icon">
                            <svg id="Star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13.256" height="13.183" viewBox="0 0 13.256 13.183">
                                <defs>
                                <clipPath id="clip-path">
                                    <rect id="Прямоугольник_5179" data-name="Прямоугольник 5179" width="13.256" height="13.183" transform="translate(0 0)" fill="none"/>
                                </clipPath>
                                </defs>
                                <g id="Сгруппировать_36839" data-name="Сгруппировать 36839" transform="translate(0 0)" clip-path="url(#clip-path)">
                                <path id="Контур_92589" data-name="Контур 92589" d="M6.154.341a.5.5,0,0,1,.948,0l1.29,3.837a.5.5,0,0,0,.461.341l3.917.105a.5.5,0,0,1,.3.889L9.919,8.052a.5.5,0,0,0-.167.525l1.121,3.97a.5.5,0,0,1-.774.541l-3.177-2.3a.5.5,0,0,0-.586,0l-3.177,2.3a.5.5,0,0,1-.774-.541l1.121-3.97a.5.5,0,0,0-.167-.525L.186,5.513a.5.5,0,0,1,.3-.889L4.4,4.519a.5.5,0,0,0,.461-.341Z" fill="#fff"/>
                                </g>
                            </svg>
                        </span>
                        <p class="tab-link-name">${item.label.toUpperCase()}</p>
                </label>`).appendTo($wrapper.find(".btn-group-toggle"));
                if(in_list(previous_selected_services, item.value)){
                    found_service = true;
                    $checkbox.find("input").prop("checked", true);
                }
            });
        });

        if(this.is_mobile_device){
            this.$actions = $(`<div id="submit-book-form" class="js-form-field text-center position-fixed book-submit-wrapper justify-content-between">
            <div class="back-quotes-btn-wrap">
                <button class="back-quotes-btn back-bac-btn btn align-items-center back-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.541" height="11.039" viewBox="0 0 15.541 11.039">
                        <g id="arrow" transform="translate(2074.035 1089.548) rotate(180)">
                            <g id="Сгруппировать_11542" data-name="Сгруппировать 11542" transform="translate(2059.344 1079.711)">
                                <line id="Линия_209" data-name="Линия 209" x2="13.558" transform="translate(0 4.398)" fill="none" stroke="#161617" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
                                <path id="Контур_27836" data-name="Контур 27836" d="M2072.273,1083.088l4.317,4.317-4.317,4.318" transform="translate(-2062.749 -1083.088)" fill="none" stroke="#161617" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
                            </g>
                        </g>
                    </svg>
                    Back
                </button>
            </div>
            <button type="button" class="btn btn-next btn-dark btn-xl submit-bac js-submit-bac-previ-mobile confirm-btn disabled">CONTINUE</button>
        </div>`).appendTo(this.$services_wrapper);
        }else{
            this.$actions = $(`<div class="js-form-field text-center position-absolute book-submit-wrapper justify-content-between">
                <div class="back-quotes-btn-wrap">
                    <button class="back-quotes-btn back-bac-btn btn align-items-center back-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.541" height="11.039" viewBox="0 0 15.541 11.039">
                            <g id="arrow" transform="translate(2074.035 1089.548) rotate(180)">
                                <g id="Сгруппировать_11542" data-name="Сгруппировать 11542" transform="translate(2059.344 1079.711)">
                                    <line id="Линия_209" data-name="Линия 209" x2="13.558" transform="translate(0 4.398)" fill="none" stroke="#161617" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
                                    <path id="Контур_27836" data-name="Контур 27836" d="M2072.273,1083.088l4.317,4.317-4.317,4.318" transform="translate(-2062.749 -1083.088)" fill="none" stroke="#161617" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
                                </g>
                            </g>
                        </svg>
                        Back
                    </button>
                </div>
                <button type="button" class="btn btn-next btn-dark btn-xl submit-bac js-submit-bac-previ confirm-btn disabled">Confirm</button>
            </div>`).appendTo(this.$services_wrapper);
        }

        if(!found_service && previous_selected_services.length){
            // checked the other service too if selected service type doesn't exists.
            this.$services_wrapper.find("input[value='Other']").prop("checked", true);
        }
        this.$confirm_btn = this.$actions.find(".confirm-btn");
        this.$back_btn = this.$actions.find(".back-btn");
        this.$services_wrapper.find("input").on("change", (e)=>{
            let $ele = $(e.currentTarget);
            let service_type = $ele.val();
            if(!$ele.is(":checked")){
                $ele.parent().removeClass("active");
                if(this.selected_services.indexOf(service_type) >= 0){
                    this.selected_services.splice(this.selected_services.indexOf(service_type), 1);
                }
            }else{
                $ele.parent().addClass("active");
                this.selected_services.push(service_type);
            }
            if(!this.selected_services.length){
                this.$confirm_btn.addClass("disabled");
            }else{
                this.$confirm_btn.removeClass("disabled");
            }
        });


        this.$confirm_btn.on("click", (e)=>{
            e.preventDefault();
            if($(e.currentTarget).hasClass("disabled")){
                return;
            }
            let args = {
                appointment_controller: this,
                services: this.selected_services
            };
            if(this.schedule_controller){
                this.schedule_controller.show();
            }else{
                this.schedule_controller = new client_portal.appt.ScheduleAppointmentController(args);
            }
            return false;
        });

        this.$back_btn.on("click", (e)=>{
            e.preventDefault();
            window.location.href = "https://www.trybond.com";
            return false;
        });

        this.$services_wrapper.find("input").trigger("change");
        this.$confirm_btn.trigger("click");
    }

    hide_services_wrapper(){
        if(this.is_mobile_device){
            this.$parent.find(".js-bac-starter-mobile")
                .addClass("d-none")
                .removeClass("d-flex");
        }else{
            this.$parent.find(".js-bac-starter")
                .addClass("d-none")
                .removeClass("d-flex");
        }
    }

    show_services_wrapper(){
        if(this.is_mobile_device){
            this.$parent.find(".js-bac-starter-mobile")
                .removeClass("d-none")
                .addClass("d-flex");
        }else{
            this.$parent.find(".js-bac-starter")
                .removeClass("d-none")
                .addClass("d-flex");
        }
    }

    hide_schedule_wrapper(){
        if(this.schedule_controller){
            this.schedule_controller.hide();
        }
    }

    get_default_view(){
        let view = "Calendar";
        if(this.search_params.get("view")){
            view = this.search_params.get("view");
        }
        return view;
    }

    get_default_data(){
        return {
            "event": this.search_params.get("appt-ref"),
            "view": this.search_params.get("view"),
            "ref_type": this.search_params.get("ref-type"),
            "ref_name": this.search_params.get("ref-name"),
            "referral_code": this.search_params.get("referral_code"),
            "user_id": this.search_params.get("user_id")
        };
    }

    set_title(){
        this.$parent.find(".book-call .bac-starter__left-title").html(
            `<h1>What products would you like to discuss?</h1>`
        );
    }

    get_full_name(){
        return "Product Discovery";
    }

    get_meeting_time(){
        return this.schedule_controller.get_appointment_time();
    }

    get_wrapper(){
        let $wrapper = null;
        if(this.is_mobile_device){
            $wrapper = $(`<main class="main-form-landing main-form-quotes main-book-call main-book-call-mobile d-block d-sm-none">
                <div class="h-100 position-relative">
                    <div class="book-call h-100 overflow-auto">
                        <div class="h-100 overflow-auto">
                            <form action="#" class="book-a-call d-none h-100">
                                <div class="h-100">
                                    <div class="bac-starter js-bac-starter-mobile d-flex flex-wrap h-100">
                                        <div class="bac-starter__full-col w-100 h-100">
                                            <div class="bac-starter__form-inner d-flex flex-column services-main-wrapper">
                                                <div class="bac-main-info__form-title bac-starter__main-mob-title mx-auto text-center helvetica-font">
                                                    <h3 class="product-title-header">What products would you like to discuss?</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-none bac-main-info js-bac-main-info-mobile flex-column h-100 calendar-main-wrapper"></div>
                                    <div class="d-none bac-confirmed js-bac-confirmed-mobile h-100 confirmation-wrapper">
                                        <div class="bac-confirmed__inner d-flex flex-column">
                                            <div class="bac-confirmed__icon d-flex justify-content-center mb-4">
                                                <img src="/assets/bond_assets/images/quotes-great-image.svg" alt="go-it-quotes-icon" />
                                            </div>
                                            <div class="bac-confirmed__title text-center helvetica-font">
                                                <h4>Confirmed</h4>
                                            </div>                        
                                            <div class="bac-confirmed__slogan text-center helvetica-font  booked-with-name-wrapper">
                                            </div>
                                            <div class="bac-confirmed__content-box">
                                                <div class="bac-confirmed__content-box-title text-center helvetica-font d-flex align-items-center justify-content-center">
                                                    <div class="bac-confirmed__content-box-icon d-flex">
                                                        <img class="img-fluid w-100" src="/assets/bond_assets/images/icons/bac-time-icon.svg" alt="bac-time-icon">
                                                    </div>
                                                    <h3 class="confirm-meeting-time"></h3>
                                                </div>
                                                <div class="bac-confirmed__content-setup-list d-flex flex-column">
                                                    <div class="bac-confirmed__content-setup-item">
                                                        <div class="bac-confirmed__content-box-icon d-flex">
                                                            <img class="img-fluid w-100" src="/assets/bond_assets/images/icons/bac-calendar-icon.svg" alt="bac-time-icon">
                                                        </div>
                                                        <div class="bac-confirmed__content-setup-text helvetica-font  confirmation-time"></div>
                                                    </div>
                                                    <div class="bac-confirmed__content-setup-item">
                                                        <div class="bac-confirmed__content-box-icon d-flex">
                                                            <img class="img-fluid w-100" src="/assets/bond_assets/images/icons/bac-earth-icon.svg" alt="bac-earth-icon">
                                                        </div>
                                                        <div class="bac-confirmed__content-setup-text helvetica-font">
                                                            <span>Eastern Time <br> <b>US & Canada</b></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="bac-confirmed__content-addon-text text-center helvetica-font">
                                                    <span>A calendar invitation has been sent to vour email address.</span>
                                                </div>
                                            </div>
                                            <div class="js-form-field text-center position-fixed book-confirmed-submit-wrapper">
                                                <button class="btn btn-next btn-dark btn-xl submit-bac confirm-go-home">GO HOME</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="d-none bg-overlay position-absolute h-100 w-100"></div>            
            </main>`);
        }else{
            $wrapper = $(`<main class="main-form-landing main-form-quotes main-book-call d-none d-sm-block">
                <div class="h-100 position-relative">
                    <div class="book-call h-100 overflow-auto">
                        <div class="h-100 overflow-auto">
                            <form action="#" class="book-a-call d-none h-100">
                                <div class="h-100">
                                    <div class="bac-starter js-bac-starter d-flex flex-wrap h-100">
                                        <div class="bac-starter__left-col h-100">
                                            <div class="bac-starter__left-title helvetica-font">
                                                <h1 class="product-title-header">What products would you like to discuss?</h1>
                                            </div>
                                        </div>
                                        <div class="bac-starter__right-col h-100 services-main-wrapper">
                                            <div class="bac-starter__form-inner d-flex h-100 services-wrapper"></div>
                                        </div>
                                    </div>
                                    <div class="bac-main-info js-bac-main-info flex-column h-100 calendar-main-wrapper d-none"></div>
                                    <div class="d-none bac-confirmed js-bac-confirmed h-100 confirmation-wrapper">
                                        <div class="bac-confirmed__inner d-flex flex-column">
                                            <div class="bac-confirmed__icon d-flex justify-content-center mb-4 pb-1">
                                                <img src="/assets/bond_assets/images/quotes-great-image.svg" alt="go-it-quotes-icon" />
                                            </div>
                                            <div class="bac-confirmed__title text-center helvetica-font">
                                                <h4>Confirmed</h4>
                                            </div>
                                            <div class="bac-confirmed__slogan text-center helvetica-font booked-with-name-wrapper"></div>
                                            <div class="bac-confirmed__content-box">
                                                <div class="bac-confirmed__content-box-title text-center helvetica-font d-flex align-items-center justify-content-center">
                                                    <div class="bac-confirmed__content-box-icon d-flex">
                                                        <img class="img-fluid w-100" src="/assets/bond_assets/images/icons/bac-time-icon.svg" alt="bac-time-icon">
                                                    </div>
                                                    <h3 class="confirm-meeting-time"></h3>
                                                </div>
                                                <div class="bac-confirmed__content-setup-list d-flex flex-wrap justify-content-center">
                                                    <div class="bac-confirmed__content-setup-item">
                                                        <div class="bac-confirmed__content-box-icon d-flex">
                                                            <img class="img-fluid w-100" src="/assets/bond_assets/images/icons/bac-calendar-icon.svg" alt="bac-time-icon">
                                                        </div>
                                                        <div class="bac-confirmed__content-setup-text helvetica-font confirmation-time"></div>
                                                    </div>
                                                    <div class="bac-confirmed__content-setup-item">
                                                        <div class="bac-confirmed__content-box-icon d-flex">
                                                            <img class="img-fluid w-100" src="/assets/bond_assets/images/icons/bac-webcamera-icon.svg" alt="bac-webcamera-icon">
                                                        </div>
                                                        <div class="bac-confirmed__content-setup-text helvetica-font">
                                                            <span>Web conferencing details provided upon confirmation.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="bac-confirmed__content-addon-text text-center helvetica-font">
                                                    <span>A calendar invitation has been sent to vour email address.</span>
                                                </div>
                                            </div>
                                            <div id="submit-book-confirmed" class="js-form-field text-center book-confirmed-submit-wrapper">
                                                <button class="btn btn-next btn-dark btn-xl submit-bac confirm-go-home">GO HOME</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="d-none bg-overlay position-absolute h-100 w-100"></div>
            </main>`);
        }
        $wrapper.appendTo("body > .wrapper > .content");
        return $wrapper;
    }
};

/*
    Appointment Cancelation Controller
*/
client_portal.appt.AppointmentCancelationController = class AppointmentCancelationController{

    constructor(args){
        $.extend(this, args);
        this.make();
    }

    make(){
        this.$wrapper = this.get_wrapper();
        this.bind_events();
        this.show_if_view_type_is_cancel();
    }

    hide(){
        this.appointment_controller.$parent
            .removeClass("d-none");
        if(this.appointment_controller.is_mobile_device){
            this.appointment_controller.$parent.addClass("d-block");
        }else{
            this.appointment_controller.$parent.addClass("d-sm-block");
        }
        this.$wrapper.addClass("d-none");

    }
    show(){

        this.appointment_controller.$parent
            .addClass("d-none");

        if(this.appointment_controller.is_mobile_device){
            this.appointment_controller.$parent.removeClass("d-block");
        }else{
            this.appointment_controller.$parent.removeClass("d-sm-block");
        }

        this.$wrapper.removeClass("d-none");
    }

    show_if_view_type_is_cancel(){
        if(this.appointment_controller.get_default_view() == "Cancel"){
            this.show();
        }
    }

    bind_events(){
        this.$schedule_call_btn = this.$wrapper.find(".schedule-call-btn");
        this.$back_btn = this.$wrapper.find(".back-btn");

        this.$schedule_call_btn.on("click", (e)=>{
            e.preventDefault();
            this.hide();
            return false;
        });

        this.$back_btn.on("click", (e)=>{
            e.preventDefault();
            window.location.href = "https://www.trybond.com";
            return false;
        });
    }

    get_wrapper(){
        return $(`<main class="landing d-none">
            <section class="form-results pt-sm-0">
                <div class="container-form">
                    <div class="d-flex pt-3">
                        <div class="d-flex flex-column align-items-sm-center justify-content-sm-start w-100 pt-2 pt-xxl-4">
                            <div class="row justify-content-center w-100 pt-2 pt-sm-3 ml-0 mr-0">
                                <div class="col-12 col-md-9">
                                    <div class="py-sm-3 py-xxl-5 px-sm-3">
                                        <div id="js-content-box-opacity" class="px-0 px-lg-5 mx-0">

                                            <div class="col-12 text-center mb-4 py-0">
                                                <img src="/assets/bond_assets/images/appoint-cancelation-illustration.svg"
                                                    alt="illistr-submitting" class="img-fluid">
                                            </div>

                                            <div class="col-12 col-md-10 col-lg-7 mx-auto text-center mb-3 pb-1">
                                                <h3 class="helvetica-font submitting-text-title mb-3 mb-md-0">Thank you for letting us know you can’t make your appointment.</h3>
                                            </div>

                                            <div class="col-12 col-md-11 col-lg-8 px-sm-3 text-center mb-5 mx-auto">
                                                <p class="helvetica-font submitting-text-description mb-0">Feel free to reschedule at a time that’s convenient for you. You can also learn more about our line of products by visiting our home page.</p>
                                            </div>

                                            <div class="col-12 d-flex submitting-cta-wrap justify-content-center">
                                                <a href="#" class="submitting-back-to-btn back-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.554 29.554">
                                                        <g id="icon" transform="translate(29.554 29.554) rotate(180)">
                                                        <path id="Контур_92309" data-name="Контур 92309" d="M14.777,0A14.777,14.777,0,1,1,0,14.777,14.777,14.777,0,0,1,14.777,0Z" fill="#161616"/>
                                                        <g id="Сгруппировать_35422" data-name="Сгруппировать 35422" transform="translate(12.212 19.96) rotate(-90)">
                                                            <g id="Сгруппировать_35411" data-name="Сгруппировать 35411" transform="translate(0)">
                                                            <path id="Контур_92308" data-name="Контур 92308" d="M0,0,5.184,6.139,10.367,0" fill="none" stroke="#fff" stroke-width="3"/>
                                                            </g>
                                                        </g>
                                                        </g>
                                                    </svg>
                                                    <span>BACK TO HOME</span>
                                                </a>
                                                <input type="button" name="next" class="next btn submit-call-btn btn-dark btn-xl mt-0 schedule-call-btn" value="SCHEDULE CALL" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>`).appendTo($("body > .wrapper > .content"));
    }
};