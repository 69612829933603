/*
    Add Appointment user
*/
frappe.provide("client_portal.appt");

client_portal.appt.UserInfoController = class UserInfoController{

    constructor(args){
        $.extend(this, args);
        this.guests_list = [];
        this.guests_list_dict = {};
        this.make();
    }

    make(){
        this.$wrapper = this.get_wrapper();
        this.setup_mobile_classes();
        this.setup_actions();
        this.setup_events();
    }

    setup_mobile_classes(){
    }

    setup_actions(){
        this.$back_btn = this.$wrapper.find("button.back-btn");
        this.$confirm_btn = this.$wrapper.find("button.confirm-btn");

        this.$back_btn.on("click", (e)=>{
            this.hide();
            return false;
        });

        this.$confirm_btn.on("click", (e)=>{
            if($(e.currentTarget).hasClass("disabled")){
                return;
            }
            this.book_appointment().then((data)=>{
                this.show_confirmation(data);
            });
            return false;
        });
    }

    book_appointment(){
        return new Promise((resolve, reject)=>{
            let params = this.appointment_controller.get_default_data();
            this.data.referral_code = params.referral_code;
            frappe.call({
                method: "client_portal.www.appt.book_appointment",
                args:{
                    user_info: this.data,
                    guests: this.get_guest_list(),
                    selected_slot: this.get_selected_slot(),
                    services: this.get_selected_services(),
                },
                freeze: true,
                freeze_message: __("Please wait while we're confirming your appointment"),
                callback: (res)=>{
                    resolve(res.message);
                }
            });
        });
    }

    show_confirmation(data){
        let selected_slot = this.get_selected_slot();
        let formatted_data = this.schedule_controller.calendar_controller.get_start_end_and_date_in_formatted(selected_slot);
        let user_full_name = data.user_full_name;

        let $confim_wrapper = this.appointment_controller.$confirm_message;
        $confim_wrapper.find(".booked-with-name-wrapper")
            .html(`<h4>You are scheduled with ${user_full_name}</h4>`);
        
        $confim_wrapper.find(".confirmation-time")
            .html(`<h1><span><b>${formatted_data.starts_on} - ${formatted_data.ends_on}</b> <br> ${formatted_data.date}</span></h1>`);
        
        $confim_wrapper.find(".confirm-meeting-time").text(`${this.appointment_controller.get_meeting_time()} Minute Meeting`);
        
        if(this.appointment_controller.is_mobile_device){
            this.appointment_controller.$parent.find('.js-bac-main-info-mobile').addClass('d-none');
        }else{
            this.appointment_controller.$parent.find('.js-bac-main-info').addClass('d-none');
        }
        $confim_wrapper.removeClass("d-none");
    }

    get_guest_list(){
        let data = [];
        this.guests_list.forEach((guest)=>{
            data.push(guest.data);
        });

        return data;
    }

    get_selected_slot(){
        let selected_slot = this.schedule_controller.calendar_controller.selected_slot;
        return selected_slot;
    }

    get_selected_services(){
        return this.appointment_controller.selected_services;
    }

    hide(){
        this.$wrapper
            .removeClass("d-block")
            .addClass("d-none");
        
        if(this.appointment_controller.is_mobile_device){
            this.schedule_controller.$time_selection_wrapper
                .removeClass("d-none");
            this.schedule_controller.$actions.removeClass("d-none");

        }else{
            this.schedule_controller.$schedule_wrapper
                .find(".js-bac-display-with-calendar")
                .removeClass("d-none")
                .addClass("d-flex");
        }
    }

    show(){
        if(this.schedule_controller.appointment_controller.is_mobile_device){
            this.schedule_controller.$time_selection_wrapper
                .addClass("d-none");
            this.schedule_controller.$actions.addClass("d-none");
            this.set_meeting_info();
        }else{
            this.schedule_controller.$schedule_wrapper
                .find(".js-bac-display-with-calendar")
                .removeClass("d-flex")
                .addClass("d-none");
        }

        this.$wrapper
            .removeClass("d-none")
            .addClass("d-block");
    }

    set_meeting_info(){
        
        this.$wrapper.find(".user-header-appointment-time")
            .text(this.schedule_controller.get_formatted_appointment_time());

        let selected_slot = this.schedule_controller.calendar_controller.selected_slot;
        let formatted_data = this.schedule_controller.calendar_controller.get_start_end_and_date_in_formatted(selected_slot);
        this.$wrapper
            .find(".user-header-date")
            .html(`<span><b>${formatted_data.starts_on} - ${formatted_data.ends_on} </b> ${formatted_data.date}</span>`);
    }

    setup_events(){
        this.data = {
            "reference_doctype": this.appointment_controller.search_params.get("ref-type"),
            "reference_name": this.appointment_controller.search_params.get("ref-name")
        };
        frappe.ui.form.add_default_handler(this.$wrapper);
        this.$wrapper.find("input, select").on("change", (e)=>{
            this.parse_and_set_value(e);
        });
        this.$wrapper.find("input").trigger("change");
    }

    parse_and_set_value(e){
        let $target = $(e.currentTarget);
        let val = $target.val();
        if($target.attr("data-fieldname") == "meeting_type" && !$target.is(":checked")){
            val = "";
        }
        this.data[$target.attr("data-fieldname")] = val;
        this.toggle_confirm_action()
    }
    
    toggle_confirm_action(){
        if(this.data.full_name && this.data.email && this.data.phone && this.data.meeting_type){
            this.$confirm_btn.removeClass("disabled");
        }else{
            this.$confirm_btn.addClass("disabled");
        }
    }

    get_wrapper(){
        let $wrapper;
        if(this.appointment_controller.is_mobile_device){
            $wrapper = $(`<div class="h-100 d-none bac-main-info__guest-col-mobile js-bac-display-guests-mobile" id="bac-main-info-display-guests-mobile">
                <div class="bac-main-info__form-content-inner bac-main-info__form-guest-content-inner d-flex justify-content-center h-100">
                    <div class="bac-main-info__form-guests-col-mobile position-relative">
                        <div class="d-flex bac-main-info__header bac-main-info-header align-items-center">
                            <div class="d-flex align-items-center">
                                <div class="bac-main-info-header__logo">
                                    <img class="img-fluid" src="/assets/bond_assets/images/icons/bac-info-logo.png" alt="bac-info-logo">
                                </div>
                                <div class="d-flex flex-column">
                                    <div class="bac-main-info-header__name js-bac-info-header-name helvetica-font">
                                        <span>${this.appointment_controller.get_full_name()}</span>
                                    </div>

                                    <ul class="bac-main-info-header__stats-list js-bac-stats-list d-flex flex-column">
                                        <li>
                                            <div class="bac-main-info-header__stats-icon d-flex">
                                                <img class="img-fluid" src="/assets/bond_assets/images/icons/bac-time-icon-dark.svg" alt="bac-time-icon">
                                            </div>
                                            <div class="bac-main-info-header__stats-text helvetica-font">
                                                <span class="user-header-appointment-time"></span>
                                            </div>
                                        </li>
                                        <li class="">
                                            <div class="bac-main-info-header__stats-icon d-flex">
                                                <img class="img-fluid" src="/assets/bond_assets/images/icons/bac-calendar-icon-dark.svg" alt="bac-calendar-icon">
                                            </div>
                                            <div class="bac-main-info-header__stats-text helvetica-font user-header-date"></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="bac-main-info__form-title helvetica-font">
                            <h4>${"Enter Details"}</h4>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex flex-column justify-content-center">
                                <label class="bac-main-info__form-label" for="">NAME</label>
                                <div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper"
                                    data-fieldname="full_name">
                                    <input class="awesomplete js-text-form form-control border-0" aria-describedby="NAME" data-fieldname="full_name" placeholder="" data-options="Full Name"/>
                                    ${frappe.ui.form.custom_icons["Data"]}
                                    ${this.get_error_tooltip()}
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 d-flex flex-column justify-content-center">
                                <label class="bac-main-info__form-label" for="">EMAIL</label>
                                <div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper"
                                    data-fieldname="email" data-options="Email">
                                    <input class="awesomplete js-text-form form-control border-0" aria-describedby="EMAIL" placeholder="" data-fieldname="email" data-options="Email"/>
                                    ${frappe.ui.form.custom_icons["Data"]}
                                    ${this.get_error_tooltip()}
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 d-flex flex-column justify-content-center">
                                <label class="bac-main-info__form-label" for="">PHONE</label>
                                <div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper"
                                    data-fieldname="phone" data-options="Phone">
                                    <input class="awesomplete js-text-form form-control border-0" aria-describedby="PHONE" placeholder="" data-fieldname="phone" data-options="Phone"/>
                                    ${frappe.ui.form.custom_icons["Data"]}
                                    ${this.get_error_tooltip()}
                                </div>
                            </div>
                        </div>
                        <div class="row guest-list"></div>
                        <div class="row guest-add-controls">
                            <div class="col-12 d-flex justify-content-center">
                                <button class="bac-main-info__add-guest-btn js-btn-add-guest">
                                    <span>ADD GUESTS</span>
                                    ${frappe.ui.form.custom_icons["Plus"]}
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 pt-4 mt-2">
                                <label class="bac-main-info__form-label" for="">HOW WOULD YOU LIKE TO CONNECT?</label>
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <div class="btn-group-toggle btn-group-radio btn-group-lg d-flex justify-content-center w-100"
                                        data-toggle="buttons"> 
                                    <label class="d-flex justify-content-between align-items-center btn btn-outline-warning mr-2 mr-sm-3">
                                        <input type="radio" name="car-status" value="Phone" data-fieldname="meeting_type">
                                        <span class="label-placeholder helvetica-font">Phone</span>
                                        ${frappe.ui.form.custom_icons["Check"]}
                                    </label>
                                    <label class="d-flex justify-content-between align-items-center btn btn-outline-warning">
                                        <input type="radio" name="car-status" value="Video" data-fieldname="meeting_type">
                                        <span class="label-placeholder helvetica-font">Video</span>
                                        ${frappe.ui.form.custom_icons["Check"]}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="submit-book-form-guest-mobile"
                    class="js-form-field text-center position-fixed book-submit-wrapper justify-content-between">
                    <div class="back-quotes-btn-wrap">
                        <button class="back-quotes-btn back-bac-btn js-back-bac-guest-mobile btn align-items-center back-btn">
                            ${frappe.ui.form.custom_icons["Back Button"]}
                            Back
                        </button>
                    </div>
                    <button type="button" class="btn btn-next btn-dark btn-xl submit-bac js-submit-bac-guest-mobile confirm-btn disabled">CONTINUE</button>
                </div>

            </div>`)
        }else{
            $wrapper = $(`<div class="h-100 d-none js-bac-display-guests">
                <div class="bac-main-info__form-content-inner d-flex justify-content-center h-100">
                    <div class="bac-main-info__form-col bac-main-info__form-guests-col position-relative">
                        <div class="bac-main-info__form-guests-inner">
                            <div class="bac-main-info__form-title text-center helvetica-font">
                                <h4>${"Enter Details"}</h4>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex flex-column justify-content-center">
                                    <label class="bac-main-info__form-label" for="">NAME</label>
                                    <div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper"
                                        data-fieldname="full_name">
                                        <input class="awesomplete js-text-form form-control border-0" aria-describedby="NAME" placeholder="" data-fieldname="full_name" data-options="Full Name"/>
                                        ${frappe.ui.form.custom_icons["Data"]}
                                        ${this.get_error_tooltip()}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 d-flex flex-column justify-content-center">
                                    <label class="bac-main-info__form-label" for="">EMAIL</label>
                                    <div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper" data-fieldname="email">
                                        <input class="awesomplete js-text-form form-control border-0" aria-describedby="EMAIL" placeholder="" data-fieldname="email" data-options="Email"/>
                                        ${frappe.ui.form.custom_icons["Data"]}
                                        ${this.get_error_tooltip()}
                                    </div>
                                </div>
                                <div class="col-6 d-flex flex-column justify-content-center">
                                    <label class="bac-main-info__form-label" for="">PHONE</label>
                                    <div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper" data-fieldname="phone">
                                        <input class="awesomplete js-text-form form-control border-0" aria-describedby="PHONE" placeholder="" data-fieldname="phone" data-options="Phone"/>
                                        ${frappe.ui.form.custom_icons["Data"]}
                                        ${this.get_error_tooltip()}
                                    </div>
                                </div>
                            </div>
                            <div class="row guest-list"></div>
                            <div class="row guest-add-controls">
                                <div class="col-12 d-flex justify-content-center">
                                    <button class="bac-main-info__add-guest-btn js-btn-add-guest">
                                        <span>ADD GUESTS</span>
                                        ${frappe.ui.form.custom_icons["Plus"]}
                                    </button>
                                </div>
                            </div>  
                            <div class="row">
                                <div class="col-12 pt-4 mt-2">
                                    <label class="bac-main-info__form-label" for="">HOW WOULD YOU LIKE TO CONNECT?</label>
                                </div>
                                <div class="col-12 d-flex justify-content-center">
                                    <div class="btn-group-toggle btn-group-radio btn-group-lg d-flex justify-content-center w-100"
                                            data-toggle="buttons"> 
                                        <label class="d-flex justify-content-between align-items-center btn btn-outline-warning mr-2 mr-sm-3">
                                            <input type="radio" name="car-status" value="Phone" data-fieldname="meeting_type">
                                            <span class="label-placeholder helvetica-font">Phone</span>
                                            ${frappe.ui.form.custom_icons["Check"]}
                                        </label>
                                        <label class="d-flex justify-content-between align-items-center btn btn-outline-warning">
                                            <input type="radio" name="car-status" value="Video" data-fieldname="meeting_type">
                                            <span class="label-placeholder helvetica-font">Video</span>
                                            ${frappe.ui.form.custom_icons["Check"]}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="submit-book-form-guest"
                            class="js-form-field text-center position-absolute book-submit-wrapper">
                            <div class="back-quotes-btn-wrap">
                                <button type="button" class="back-quotes-btn js-guest-step-btn-back back-bac-btn btn back-btn">
                                    ${frappe.ui.form.custom_icons["Back Button"]}
                                    Back
                                </button>
                            </div>
                            <button type="button" class="btn btn-next btn-dark btn-xl submit-bac js-submit-bac-guest confirm-btn disabled">Confirm</button>
                        </div>
                    </div>
                </div>
            </div>`);
        }
        $wrapper.appendTo(this.schedule_controller.$schedule_wrapper);
        let default_values = this.appointment_controller.get_default_user_values();
        if(default_values.full_name){
            $wrapper.find("input[data-fieldname='full_name']").val(default_values.full_name);
        }
        if(default_values.phone){
            $wrapper.find("input[data-fieldname='phone']").val(default_values.phone);
        }
        if(default_values.email){
            $wrapper.find("input[data-fieldname='email']").val(default_values.email);
        }
        this.setup_guests_controls($wrapper);
        return $wrapper;
    }

    setup_guests_controls($parent){
        this.$guests_list_wrapper = $parent.find(".guest-list");

        this.$guest_controls = $parent.find(".guest-add-controls");

        this.$guest_controls.find("button.js-btn-add-guest").on("click", (e)=>{
            new client_portal.appt.AddGuestController({
                appointment_controller: this.appointment_controller,
                schedule_controller: this.schedule_controller,
                user_info_controller: this,
            });
            return false;
        });
    }

    get_error_tooltip(){
        return `<div class="w-100 invalid-tooltip small py-3 d-none" data-fieldname="">
            <div class="d-flex align-items-center justify-content-center">
                <div class="mr-2 pb-1">
                    <img src="/assets/bond_assets/images/icons/three-dots.svg" alt="" class="img-fluid">
                </div>
                <div class="text-left error-message"></div>
            </div>
        </div>`;
    }

}
/*
    Add Appointment Guest
*/
client_portal.appt.AddGuestController = class AddGuestController{
    constructor(args){
        $.extend(this, args);
        this.data = {};
        this.make();
    }

    make(){
        this.$wrapper = this.get_wrapper();
        this.$back_btn = this.$wrapper.find("button.back-btn");
        this.$confirm_btn = this.$wrapper.find("button.confirm-btn");
        this.$close_btn = this.$wrapper.find(".close-btn");
        this.setup_actions();
        this.setup_handlers();
        this.show();
    }

    show(){
        this.$wrapper.removeClass("d-none");
        $(".bg-overlay").removeClass("d-none");
    }

    hide(){
        this.$wrapper.addClass("d-none");
        $(".bg-overlay").addClass("d-none");
    }

    setup_actions(){

        this.$close_btn.on("click", (e)=>{
            if(!this.edit_mode){
                this.remove();
            }else{
                this.hide();
            }
        });

        this.$back_btn.on("click", (e)=>{
            if(!this.edit_mode){
                this.remove();
            }else{
                this.hide();
            }
            return false;
        });

        this.$confirm_btn.on("click", (e)=>{
            if($(e.currentTarget).hasClass("disabled")){
                return;
            }
            this.add_guest_to_guest_list();
            this.hide();
            return false;
        });
    }

    add_guest_to_guest_list(){
        let guest_id = frappe.dom.get_unique_id();
        this.id = guest_id;
        this.$guest_record_wrapper = $(`<div class="col-12" id="${guest_id}">
            <div class="bac-main-info__guest-item bac-main-info-guest-item">
                <div class="bac-main-info-guest-item__name helvetica-font">
                    <span>${this.data.first_name} ${this.data.last_name}</span>
                </div>
                <div class="bac-main-info-guest-item__control-box">
                    <button class="bac-main-info-guest-item__control-btn js-btn-edit-guest" guest-id="${guest_id}">
                        <span>EDIT</span>
                        <div class="control-btn-icon">
                            <svg id="PENCIL" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.352" height="13.394" viewBox="0 0 12.352 13.394">
                                <defs>
                                <clipPath id="clip-path">
                                    <rect id="Прямоугольник_5177" data-name="Прямоугольник 5177" width="12.352" height="13.394" fill="#161616"/>
                                </clipPath>
                                </defs>
                                <g id="Сгруппировать_36833" data-name="Сгруппировать 36833" clip-path="url(#clip-path)">
                                <path id="Контур_92588" data-name="Контур 92588" d="M11.415.733A3.268,3.268,0,0,1,11.609,5.1L7.051,1.024A3.175,3.175,0,0,1,11.415.733ZM10.83,5.907,5.437,11.746,1.071,13.327A.761.761,0,0,1,.037,12.4L.879,7.673,6.272,1.834Z" transform="translate(0 0)" fill="#161616" fill-rule="evenodd"/>
                                </g>
                            </svg>                                                                 
                        </div>
                    </button>
                    <button class="bac-main-info-guest-item__control-btn js-btn-guest-remove" guest-id="${guest_id}">
                        <span>REMOVE</span>
                        <div class="control-btn-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12.213" height="12.214" viewBox="0 0 12.213 12.214">
                                <g id="remove_icon" data-name="remove icon" transform="translate(-167.564 -1297.614) rotate(45)">
                                <path id="Контур_71762" data-name="Контур 71762" d="M1038.51,803.435V792.163" transform="translate(6.164 1.268)" fill="none" stroke="#161616" stroke-linecap="square" stroke-width="3"/>
                                <path id="Контур_71763" data-name="Контур 71763" d="M0,11.272V0" transform="translate(1050.31 799.066) rotate(90)" fill="none" stroke="#161616" stroke-linecap="square" stroke-width="3"/>
                                </g>
                            </svg>                                                               
                        </div>
                    </button>
                </div>
            </div>
        </div>`).appendTo(this.user_info_controller.$guests_list_wrapper);

        this.user_info_controller.guests_list.push(this);
        this.user_info_controller.guests_list_dict[guest_id] = this;

        this.$guest_record_wrapper.find("button.js-btn-edit-guest").on("click", (e)=>{
            this.edit_mode = true;
            this.set_title("Edit Guest Information");
            this.show();
            return false;
        });

        this.$guest_record_wrapper.find("button.js-btn-guest-remove").on("click", (e)=>{
            this.remove();
            return false;
        });
    }

    remove(){
        let idx = this.user_info_controller.guests_list.indexOf(this);
        this.user_info_controller.guests_list.splice(idx);
        delete this.user_info_controller.guests_list_dict[this.id];   
        this.$wrapper.remove();
        if(this.$guest_record_wrapper){
            this.$guest_record_wrapper.fadeOut(2);
        }
        $(".bg-overlay").addClass("d-none");
    }

    setup_handlers(){
        frappe.ui.form.add_default_handler(this.$wrapper);
        this.$wrapper.find("input").on("change", (e)=>{
            this.parse_and_set_value(e);
        });
    }

    set_title(title){
        this.$wrapper.find(".bac-main-info__form-title").html(`<h1>${title}</h1>`);
    }

    parse_and_set_value(e){
        let $target = $(e.currentTarget);
        let value = $target.val();
        this.data[$target.attr("data-fieldname")] = value;
        if(this.data.first_name && this.data.last_name){
            this.data.full_name = `${this.data.first_name} ${this.data.last_name}`;
        }
        this.toggle_actions();
    }

    toggle_actions(){
        if(this.data.first_name && this.data.last_name && this.data.phone && this.data.email){
            this.$confirm_btn.removeClass("disabled");
        }else{
            this.$confirm_btn.addClass("disabled");
        }
    }

    get_wrapper(){
        let $wrapper = $(`<div class="d-none popup popup-add-guest popup-add-guest--edit js-popup-edit-guest position-fixed text-center">
            <div class="popup-close position-absolute">
                <button id="popup-close-btn" class="btn popup-close-btn close-btn">${frappe.ui.form.custom_icons['Close']}</button>
            </div>
            <div class="bac-main-info__form-title text-center helvetica-font">
                <h4>Enter Guest Information</h4>
            </div>
            <div class="row">
                <div class="col-6 d-flex flex-column justify-content-center">
                    <label class="bac-main-info__form-label" for="">FIRST NAME</label>
                    <div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper"
                        data-fieldname="first_name">
                        <input class="awesomplete js-text-form form-control border-0"
                                aria-describedby="FIRST NAME" placeholder="" data-fieldname="first_name" data-fieldtype="Data" data-options="Name"/>
                        ${frappe.ui.form.custom_icons["Data"]}
                        ${this.get_invalid_tooltip()}
                    </div>
                </div>
                <div class="col-6 d-flex flex-column justify-content-center">
                    <label class="bac-main-info__form-label" for="">LAST NAME</label>
                    <div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper"
                        data-fieldname="last_name">
                        <input class="awesomplete js-text-form form-control border-0"
                        aria-describedby="LAST NAME" placeholder="" data-fieldname="last_name" data-fieldtype="Data" data-options="Name"/>
                        ${frappe.ui.form.custom_icons["Data"]}
                        ${this.get_invalid_tooltip()}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex flex-column justify-content-center">
                    <label class="bac-main-info__form-label" for="">EMAIL</label>
                    <div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper"
                        data-fieldname="email">
                        <input class="awesomplete js-text-form form-control border-0"
                            aria-describedby="EMAIL" placeholder="" data-fieldname="email" data-fieldtype="Data" data-options="Email"/>
                        ${frappe.ui.form.custom_icons["Data"]}
                        ${this.get_invalid_tooltip()}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex flex-column justify-content-center">
                    <label class="bac-main-info__form-label" for="">PHONE</label>
                    <div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper"
                        data-fieldname="Phone">
                        <input class="awesomplete js-text-form form-control border-0"
                            aria-describedby="PHONE" placeholder="" data-fieldname="phone" data-fieldtype="Data" data-options="Phone"/>
                        ${frappe.ui.form.custom_icons["Data"]}
                        ${this.get_invalid_tooltip()}
                    </div>
                </div>
            </div>
            <div class="js-form-field text-center d-flex align-items-centers justify-content-between w-100 popup-add-guest__cta-wrap">
                <div class="back-quotes-btn-wrap d-flex align-items-center">
                    <button class="back-quotes-btn back-bac-btn btn back-btn">
                        ${frappe.ui.form.custom_icons["Back Button"]}
                        Back
                    </button>
                </div>
                <button class="btn btn-next btn-dark btn-xl submit-bac popup-close-btn confirm-btn disabled">CONFIRM</button>
            </div>
        </div>`).appendTo(this.appointment_controller.$parent);
        return $wrapper;
    }

    get_invalid_tooltip(){
        return `<div class="w-100 invalid-tooltip small py-3 d-none" data-fieldname="">
            <div class="d-flex align-items-center justify-content-center">
                <div class="mr-2 pb-1">
                    <img src="/assets/bond_assets/images/icons/three-dots.svg" alt="" class="img-fluid">
                </div>
                <div class="text-left error-message"></div>
            </div>
        </div>`;
    }
};