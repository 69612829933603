/*
    Schedule Appointment Controller
*/
frappe.provide("client_portal.appt");

import "./calendar_controller";
import "./user_controller";

client_portal.appt.ScheduleAppointmentController = class ScheduleAppointmentController{
    constructor(args){
        $.extend(this, args);
        this.make();
    }

    async make(){
        this.$wrapper = this.get_wrapper();
        this.show();
        this.calendar_controller = new client_portal.appt.CalendarController({
            schedule_controller: this,
            appointment_controller: this.appointment_controller,
        });
    }

    async show(){
        this.data = await this.get_schedule();
        if(this.appointment_controller.is_mobile_device){
            this.appointment_controller.hide_services_wrapper();
            this.$schedule_wrapper.removeClass("d-none");
            this.$schedule_wrapper.find(".js-bac-display-calendar-mobile")
                .removeClass("d-none");
        }
        //this.set_user_info();
        this.show_schedule();
        this.set_user_header_appointment_time();
    }

    set_user_header_appointment_time(){
        this.$wrapper.find(".user-header-appointment-time").text(this.get_formatted_appointment_time());
    }

    hide(){
        if(this.appointment_controller.is_mobile_device){
            this.appointment_controller.show_services_wrapper();
            this.$schedule_wrapper
                .addClass("d-none");
            this.$schedule_wrapper
                .find(".js-bac-display-calendar-mobile")
                .addClass("d-none");
        }else{
            this.$wrapper.addClass("d-none");
        }
    }

    show_schedule(){
        this.appointment_controller.hide_services_wrapper();
        this.$wrapper
            .removeClass("d-none");
    }

    get_schedule(){
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "client_portal.www.appt.get_schedule",
                args:{
                    services: this.appointment_controller.selected_services,
                    data: this.appointment_controller.get_default_data(),
                },
                freeze: true,
                callback: (res)=>{
                    resolve(res);
                }
            });
        });
    }

    set_schedule_data(){
        return this.data;
    }

    get_appointment_time(){
        let appointment_time = 30;
        if(this.data && this.data.appointment_time){
            appointment_time = this.data.appointment_time;
        }
        return appointment_time;
    }

    get_formatted_appointment_time(){
        return `${this.get_appointment_time()} Minute Meeting`;
    }

    get_wrapper(){
        let $wrapper = this.appointment_controller.$parent.find(".calendar-main-wrapper");
        this.$user_info_wrapper = $(`<div class="bac-main-info__header bac-main-info-header d-flex align-items-center">
            <div class="d-flex mr-2">
                <div class="bac-main-info-header__logo">
                    <img class="img-fluid" src="/assets/bond_assets/images/icons/bac-info-logo.png" alt="bac-info-logo">
                </div>
                <div class="d-flex flex-column pt-2">
                    <div class="bac-main-info-header__name helvetica-font">
                        <span>${this.appointment_controller.get_full_name()}</span>
                    </div>
                    <div class="bac-main-info-header__title helvetica-font">
                        <h3 class="user-header-appointment-time"></h3>
                    </div>                                            
                </div>
            </div>
            <div class="bac-main-info-header__stats-wrapper">
                <ul class="bac-main-info-header__stats-list d-flex align-items-center">
                    <li>
                        <div class="bac-main-info-header__stats-icon d-flex">
                            <img class="img-fluid" src="/assets/bond_assets/images/icons/bac-webcamera-icon.svg" alt="bac-webcamera-icon">
                        </div>
                        <div class="bac-main-info-header__stats-text helvetica-font">
                            <span>Web conferencing details provided upon confirmation.</span>
                        </div>
                    </li>
                    <li>
                        <div class="bac-main-info-header__stats-icon user-header-date-icon d-none">
                            <img class="img-fluid" src="/assets/bond_assets/images/icons/bac-calendar-icon.svg" alt="bac-calendar-icon">
                        </div>
                        <div class="bac-main-info-header__stats-text helvetica-font user-header-date"></div>
                    </li>
                    <li>
                        <div class="bac-main-info-header__stats-icon d-flex">
                            <img class="img-fluid" src="/assets/bond_assets/images/icons/bac-earth-icon.svg" alt="bac-earth-icon">
                        </div>
                        <div class="bac-main-info-header__stats-text helvetica-font">
                            <span>Eastern Time <br> <b>US & Canada</b></span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>`).appendTo($wrapper);

        if(this.appointment_controller.is_mobile_device){
            this.$user_info_wrapper
                .removeClass("d-flex")
                .addClass("d-none");
        }

        this.set_calendar_field($wrapper);
        this.set_time_zone_field($wrapper);
        this.set_add_user_controller();
        return $wrapper;
    }

    set_add_user_controller(){
        this.user_info_controller = new client_portal.appt.UserInfoController({
            schedule_controller: this,
            appointment_controller: this.appointment_controller,
        });
    }

    set_calendar_field($wrapper){
        if(this.appointment_controller.is_mobile_device){
            this.$schedule_wrapper = $(`<div class="bac-main-info__form-content-wrapper h-100">
                <div class="h-100 d-none bac-main-info__calendar-col-mobile js-bac-display-calendar-mobile" id="bac-main-info-display-calendar">
                    <div class="bac-main-info__form-content-inner d-flex h-100 w-100"></div>
                </div>
            </div>`).appendTo($wrapper);

            this.$date_wrapper = $(`<div class="bac-main-info__form-calendar-mobile-col w-100">
                <div class="bac-main-info__form-title helvetica-font">
                    <h4>Select a Date</h4>
                </div>
                <div class="select-date-picker-wrapper">
                    <div class="input-group w-100 position-relative">
                        <input type="date" id="select-date-bac"
                            class="form-control border-0 datepicker-select-date-bac pr-0 d-none" placeholder="Select a Date">
                    </div>
                </div>
            </div>`).appendTo(this.$schedule_wrapper.find(".bac-main-info__form-content-inner"));

            this.$mobile_calendar_actions = $(`<div class="js-form-field text-center position-fixed book-submit-wrapper justify-content-between">
                <div class="back-quotes-btn-wrap">
                    <button class="back-quotes-btn back-bac-btn js-back-bac-calendar-mobile btn align-items-center back-btn">
                        ${frappe.ui.form.custom_icons["Back Button"]}
                        Back
                    </button>
                </div>
                <button type="button" class="btn btn-next btn-dark btn-xl submit-bac js-submit-bac-calendar-mobile confirm-btn disabled">CONTINUE</button>
            </div>`).appendTo(this.$schedule_wrapper.find(".js-bac-display-calendar-mobile"));
            
            this.$mobile_back_btn = this.$mobile_calendar_actions.find("button.back-btn");
            this.$mobile_confirm_btn = this.$mobile_calendar_actions.find("button.confirm-btn");
            this.$mobile_back_btn.on("click", (e)=>{
                //e.preventDefault();
                this.hide()
                return false;
            });

            this.$mobile_confirm_btn.on("click", (e)=>{
                e.preventDefault();
                if($(e.currentTarget).hasClass("disabled")){
                    return;
                }
                this.$actions.removeClass("d-none");
                this.$schedule_wrapper.find(".js-bac-display-calendar-mobile")
                    .addClass("d-none");
                
                this.$mobile_calendar_actions.addClass("d-none");
                this.$time_selection_wrapper.removeClass("d-none");
                
                return false;
            });
        }else{
            this.$schedule_wrapper = $(`<div class="bac-main-info__form-content-wrapper h-100">
                <div class="h-100 js-bac-display-with-calendar">
                    <div class="bac-main-info__form-content-inner d-flex h-100 w-100">
                    </div>
                </div>
            </div>`).appendTo($wrapper);

            this.$date_wrapper = $(`<div class="bac-main-info__form-col bac-main-info__form-calendar-col">
                <div class="bac-main-info__form-title helvetica-font">
                    <h4>Select a Date</h4>
                </div>
                <div class="select-date-picker-wrapper">
                    <div class="input-group w-100 position-relative">
                        <input type="date" id="select-date-bac"
                                class="form-control border-0 datepicker-select-date-bac pr-0 d-none"
                                placeholder="Select a Date">
                    </div>
                </div>
            </div>`).appendTo(this.$schedule_wrapper.find(".bac-main-info__form-content-inner"));
        }
    }

    set_time_zone_field($wrapper){
        if(this.appointment_controller.is_mobile_device){
            this.$time_selection_wrapper = $(`<div class="h-100 d-none bac-main-info__select-col-mobile js-bac-display-select-date-mobile" id="bac-main-info-display-select-date">
                <div class="bac-main-info__form-content-inner d-flex h-100 w-100">
                    <div class="bac-main-info__form-select-time-col-mobile position-relative w-100">
                        <div class="bac-main-info__form-title helvetica-font">
                                <h4>Select Time</h4>
                        </div>
                        <div class="bac-main-info__mini-title current-selected-date-slot-title"></div>
                        <div class="form-row btn-group-toggle btn-group-lg time-slots-wrapper" data-toggle="buttons"></div>
                    </div>
                </div>
            </div>`).appendTo(this.$schedule_wrapper);

            this.$actions = $(`<div class="js-form-field text-center position-fixed book-submit-wrapper justify-content-between">
                <div class="back-quotes-btn-wrap">
                    <button class="back-quotes-btn back-bac-btn js-back-bac-select-mobile btn align-items-center back-btn">
                        ${frappe.ui.form.custom_icons["Back Button"]}
                        Back
                    </button>
                </div>
                <button type="button" class="btn btn-next btn-dark btn-xl submit-bac js-submit-bac-select-mobile confirm-btn disabled">CONTINUE</button>
            </div>`).appendTo(this.$time_selection_wrapper);

            this.$confirm_btn = this.$actions.find("button.confirm-btn");
            this.$back_btn = this.$actions.find("button.back-btn");

            this.$back_btn.on("click", (e)=>{
                e.preventDefault();
                // hide time slots section
                this.$mobile_calendar_actions.removeClass("d-none");
                this.$time_selection_wrapper.addClass("d-none");

                // show calendar module
                this.$actions.addClass("d-none");
                this.$schedule_wrapper
                    .find(".js-bac-display-calendar-mobile")
                    .removeClass("d-none");
                
                return false;
            });

            this.$confirm_btn.on("click", (e)=>{
                e.preventDefault();
                if($(e.currentTarget).hasClass("disabled")){
                    return;
                }
                this.user_info_controller.show();
                return false;
            });

            this.$actions.addClass("d-none");

        }else{
            this.$time_selection_wrapper = $(`<div class="bac-main-info__form-col bac-main-info__form-select-time-col position-relative">
                <div class="bac-main-info__form-select-time-inner">
                    <div class="bac-main-info__form-title helvetica-font">
                        <h4>Select Time</h4>
                    </div>
                    <div class="bac-main-info__mini-title current-selected-date-slot-title"></div>
                    <div class="form-row btn-group-toggle btn-group-lg time-slots-wrapper" data-toggle="buttons">
                    </div>
                </div>
            </div>`).appendTo(this.$schedule_wrapper.find(".bac-main-info__form-content-inner"));

            this.$actions = $(`<div class="js-form-field text-center position-absolute book-submit-wrapper justify-content-between">
                <div class="back-quotes-btn-wrap">
                    <button class="back-quotes-btn back-bac-btn btn align-items-center back-btn">
                        ${frappe.ui.form.custom_icons["Back Button"]}
                        Back
                    </button>
                </div>
                <button type="button" class="btn btn-next btn-dark btn-xl submit-bac js-submit-bac-previ confirm-btn disabled">Confirm</button>
            </div>`).appendTo(this.$time_selection_wrapper);

            this.$confirm_btn = this.$actions.find("button.confirm-btn");
            this.$back_btn = this.$actions.find("button.back-btn");

            this.$back_btn.on("click", (e)=>{
                this.appointment_controller.show();
                return false;
            });

            this.$confirm_btn.on("click", (e)=>{
                if($(e.currentTarget).hasClass("disabled")){
                    return;
                }
                this.user_info_controller.show();
                return false;
            });
        }
        if(this.appointment_controller.is_mobile_device){
            this.$time_selection_wrapper.addClass("d-none");
        }
    }

    enable_confirm(){
        this.$confirm_btn.removeClass("disabled");
    }

    disable_confirm(slots){
        this.$confirm_btn.addClass("disabled");
    }

    calendar_date_callback(selected_date, slots){
        
        if(!this.appointment_controller.is_mobile_device){
            this.disable_confirm();
        }else{
            if(slots && slots.length){
                this.$mobile_confirm_btn.removeClass("disabled");
            }else{
                this.$mobile_confirm_btn.addClass("disabled");
            }
        }
    }
};